import React from "react";
import st from "./App.module.scss";
import { Link } from "react-router-dom";

export function Footer() {
  return (
    <footer>
      <Link to="/privacy" className={st.privacy}>
        Privacy Policy
      </Link>
      <br />
      <div className={st.legal}>
        Copyright 2024 Enatice Ltd, Registered in England & Wales
        <br />
        <br />
        Google Play and the Google Play logo are trademarks of Google LLC.
        <br />
        App Store is service marks of Apple Inc, registered in the US and other
        countries and regions.
      </div>
    </footer>
  );
}

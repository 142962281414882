import * as React from "react";
import { Link } from "react-router-dom";
import st from "./App.module.scss";

export const Privacy = () => {
  return (
    <div>
      <Link to="/" className={st.otherPageHeader}>
        <img
          src="/app-icon.webp"
          style={{ width: 50, borderRadius: 10, margin: 20 }}
          alt="binalert logo"
        />
        <div className={st.text}>Binalert</div>
      </Link>
      <hr />

      <iframe
        src="/privacy.html"
        style={{ outline: 0, border: 0, width: "100%", height: "90vh" }}
      ></iframe>
    </div>
  );
};

import React from "react";
import st from "./App.module.scss";
import CookieConsent from "react-cookie-consent";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { Privacy } from "./Privacy";
import { Footer } from "./Footer";
import { DeleteAccount } from "./DeleteAccount";

function Landing() {
  return (
    <>
      <div className={st.titlePage}>
        <header className={st.header}>
          <img src="/app-icon.webp" className={st.logo} alt="binalert logo" />
          <h1>Binalert</h1>
          <div>Do not miss another bin day</div>
          <div className={st.downloadLinks}>
            <a
              href="https://apps.apple.com/gb/app/binalert/id6499257945"
              target="_blank"
            >
              <img
                src="/download.webp"
                className={st.download}
                alt="Download on the app store icon"
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=uk.co.binalert.app"
              target="_blank"
            >
              <img
                src="/google-play-badge.webp"
                className={st.download}
                alt="Download on the app store icon"
              />
            </a>
          </div>
          <a href="mailto:support@binalert.co.uk">support@binalert.co.uk</a>
        </header>
        <div className={st.imageContainer}>
          <img
            src="/app_screenshot.webp"
            alt="App screenshot which shows list of bin days at an address"
          />
        </div>
      </div>
      <Footer />
    </>
  );
}

function App() {
  return (
    <Router>
      <div className={st.app}>
        <CookieConsent
          buttonStyle={{
            backgroundColor: "#1A8FA7",
            color: "#fff",
            fontSize: "16px",
            borderRadius: 5,
          }}
        >
          This website uses cookies to enhance the user experience.
        </CookieConsent>

        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/delete-account" element={<DeleteAccount />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;

import * as React from "react";
import { Link } from "react-router-dom";
import st from "./App.module.scss";
import { Footer } from "./Footer";

export const DeleteAccount = () => {
  return (
    <div>
      <Link to="/" className={st.otherPageHeader}>
        <img
          src="/app-icon.webp"
          style={{ width: 50, borderRadius: 10, margin: 20 }}
          alt="binalert logo"
        />
        <div className={st.text}>Binalert</div>
      </Link>
      <main className={st.mainContent}>
        <h1>Delete account - Steps</h1>
        <h2>Self managed (Recommended)</h2>
        You can follow this steps to delete the app
        <ul>
          <li>Login to the app</li>
          <li>Click the settings cog on the top right</li>
          <li>Under your profile section, click "Delete Account"</li>
          <li>Confirm your choice in the dialog that pops up</li>
          <li>Your account is now deleted</li>
        </ul>
        <h2>Raise request</h2>
        <li>
          Sent an email to support@binalert.co.uk from the registered email
          address
        </li>
        <li>
          After steps to verify that you really own the account, we will
          initiate account deletion
        </li>
      </main>
      <Footer />
    </div>
  );
};
